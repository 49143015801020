export const environment = {
  production: true,
  endpoint: 'https://ccmanager.cottoncare.sg/ws',
  // endpoint: 'https://ccmanagerproduction.laundryccmanager.com/ws',
  socketUrl: '',
  apiKey: '',
  notification: ''

};

